@import '../../styles/variables.scss';
@import '../../styles/utilities.scss';
@import '../../styles/typography.scss';

$custom-grid-breakpoint-small-max: 1029px;
$custom-grid-breakpoint-medium-min: 1030px;

.nav {
  height: 70px;
  display: flex;

  @media (max-width: $custom-grid-breakpoint-small-max) {
    align-items: center;
  }
}

.buttonMenu {
  border: 0;
  height: 100%;
  padding: 0 15px;
  position: relative;
  color: $color-p-white;
  background-color: transparent;

  @media (min-width: $custom-grid-breakpoint-medium-min) {
    display: none;
  }
}

.buttonClose {
  z-index: 11;
  top: 0;
  left: 0;
  width: 65px;
  height: 65px;
  position: absolute;
  padding: 15px 25px 25px 15px;
  border: 0;
  color: $color-p-white;
  background-color: transparent;
}

.navDesktop {
  @media (max-width: $custom-grid-breakpoint-small-max) {
    display: none;
  }

  @media (min-width: $custom-grid-breakpoint-medium-min) {
    display: flex;
  }

  .navItem {
    min-width: rem(175px);
    word-break: break-all;
  }

  .navLink {
    @include font-defaults-new;

    border: 0;
    width: 100%;
    height: 100%;
    display: flex;
    cursor: pointer;
    line-height: 20px;
    position: relative;
    align-items: center;
    color: $color-gray-30;
    justify-content: center;
    letter-spacing: 0.01em;
    background-color: transparent;

    &:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }

    &.active {
      font-weight: 500;
      color: $color-p-white;

      &::before {
        content: '';
        bottom: 0;
        height: 3px;
        width: 100%;
        position: absolute;
        border-radius: 10px 10px 0 0;
        background-color: $color-p-white;
      }
    }
  }
}

.navMobile {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.9);
  flex-direction: column;
  padding-top: 15vh;
  height: 100vh;

  .navItem {
    width: 70vw;
    margin: 0 auto;

    &:not(:first-child) {
      border-top: 1px solid $color-gray-300;
    }
  }

  .navLink {
    @include font-defaults-new;

    border: 0;
    width: 100%;
    padding: 30px 0;
    font-size: 20px;
    color: $color-p-white;
    background-color: transparent;

    &.active {
      font-weight: 500;
    }
  }
}
